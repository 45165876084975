import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from './confirm.component';
import {filter} from 'rxjs/operators';

export interface ConfirmButtonOptions {
    text?: string;
    colorClass?: string;
    dataTestId?: string;
}

export interface ConfirmButtonOptionsWithValue<T> extends ConfirmButtonOptions {
    confirmValue: T;
}

export interface ConfirmListItem<T> {
    displayName: string;
    value: T;
}

export interface ConfirmOptionsBase {
    title?: string;
    body?: string;
    showCancelBtn?: boolean;
    confirmBtn?: ConfirmButtonOptions;
}

export interface ConfirmOptionsDate extends ConfirmOptionsBase {
    date: true;
    initialValue?: string;
}

export interface ConfirmOptionsInput extends ConfirmOptionsBase {
    input: string;
    rows?: number;
}

export interface ConfirmOptionsInputWithEmpty extends ConfirmOptionsInput {
    allowEmpty: boolean;
}

export interface ConfirmOptionsMultiBtn<T> extends ConfirmOptionsBase {
    confirmBtns: ConfirmButtonOptionsWithValue<T>[];
}

export interface ConfirmOptionsWithList<T> extends ConfirmOptionsBase {
    list: ConfirmListItem<T>[];
    autoSelectSingleItem?: boolean;
    initialValue?: T;
}

export type ConfirmOptions<T = boolean> = ConfirmOptionsBase | ConfirmOptionsDate | ConfirmOptionsInput | ConfirmOptionsMultiBtn<T> | ConfirmOptionsWithList<T>;

export class ConfirmRequest<T> {
    options: ConfirmOptions<T>;
    response$ = new Subject<T>();
    modalRef?: NgbModalRef;

    constructor(options: ConfirmOptions<T>, modalService: NgbModal) {
        this.options = options;
        this.modalRef = modalService.open(ConfirmComponent);
        (this.modalRef.componentInstance as ConfirmComponent<T>).confirmRequest = this;
    }

    respond(confirmResponse: T) {
        this.response$.next(confirmResponse);
        this.close();
    }

    close() {
        this.response$.complete();
        this.modalRef?.dismiss();
        this.modalRef = null;
    }
}

@Injectable()
export class ConfirmService {
    currentConfirmRequest: ConfirmRequest<any>;

    constructor(private modalService: NgbModal) {
    }

    requestConfirm(options: ConfirmOptionsBase, emitFalse?: boolean): Observable<boolean>;
    requestConfirm(options: ConfirmOptionsDate, emitFalse?: boolean): Observable<string>;
    requestConfirm(options: ConfirmOptionsInput, emitFalse?: boolean): Observable<string>;
    requestConfirm(options: ConfirmOptionsInputWithEmpty, emitFalse?: boolean): Observable<string | boolean>;
    requestConfirm<T>(options: ConfirmOptionsMultiBtn<T>, emitFalse?: boolean): Observable<T>;
    requestConfirm<T>(options: ConfirmOptionsWithList<T>, emitFalse?: boolean): Observable<T>;
    requestConfirm(options: ConfirmOptions<any>, emitFalse = false): Observable<any> {
        if (this.currentConfirmRequest) this.currentConfirmRequest.close();
        this.currentConfirmRequest = new ConfirmRequest(options, this.modalService);
        this.currentConfirmRequest.modalRef.result.then(() => this.currentConfirmRequest = null).catch(() => {});
        const obs$ = new Observable(subscriber => {
            this.currentConfirmRequest.response$.subscribe(subscriber);
            return () => this.currentConfirmRequest.close();
        });
        return emitFalse ? obs$ : obs$.pipe(filter(x => !!x));
    }
}
