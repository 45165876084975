import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ConfirmOptionsDate,
    ConfirmOptionsInput,
    ConfirmOptionsInputWithEmpty,
    ConfirmOptionsMultiBtn,
    ConfirmOptionsWithList,
    ConfirmRequest,
} from './confirm.service';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm.component.html',
})
export class ConfirmComponent<T> implements OnInit {
    confirmRequest: ConfirmRequest<T>;
    value: any = null;

    @ViewChild('modal', {static: true}) modalTemplate;

    get options() {
        return this.confirmRequest.options;
    }

    get dateOptions() {
        return (this.options as ConfirmOptionsDate);
    }

    get inputOptions() {
        return (this.options as ConfirmOptionsInput);
    }

    get inputWithEmptyOptions() {
        return (this.options as ConfirmOptionsInputWithEmpty);
    }

    get list() {
        return (this.options as ConfirmOptionsWithList<T>)?.list;
    }

    get listOptions() {
        return (this.options as ConfirmOptionsWithList<T>);
    }

    get confirmBtns() {
        return (this.options as ConfirmOptionsMultiBtn<T>)?.confirmBtns;
    }

    get confirmBtn() {
        return this.options?.confirmBtn;
    }

    respond(confirmResponse) {
        this.confirmRequest.respond(confirmResponse && this.value || confirmResponse);
    }

    isDisabled() {
        if (this.inputWithEmptyOptions?.allowEmpty) return false;
        return this.listOptions?.list?.length && (!this.value || (this.value === this.listOptions.initialValue && this.listOptions.list.length > 1)) ||
            (this.dateOptions?.date || this.inputOptions?.input) && !this.value;
    }

    ngOnInit(): void {
        if (this.listOptions?.autoSelectSingleItem) {
            this.value = this.list[0].value;
        }
        if (this.list && this.listOptions?.initialValue) {
            const autoSelectWasPerformed = this.list.some(item => {
                const autoSelect = this.listOptions.initialValue === item.value; // Only works with primitives, NgSelect will produce warnings for Objects
                if (autoSelect) this.value = item.value;
                return autoSelect;
            });
            if (!autoSelectWasPerformed) console.warn(`[ConfirmComponent] The specified initial value "${this.listOptions.initialValue}" is not found among the options!`);
        } else if (this.dateOptions?.initialValue) {
            this.value = this.dateOptions.initialValue;
        }
    }
}
