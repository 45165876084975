<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{options?.title || 'Are you sure?'}}</h4>
</div>
<div class="modal-body">
    {{options?.body || 'Please confirm your action!'}}
    <div class="mt-3">
        <ng-select *ngIf="list?.length"
                   [items]="list"
                   bindLabel="displayName"
                   bindValue="value"
                   [(ngModel)]="value">
        </ng-select>
        <datepicker-select *ngIf="dateOptions?.date"
                           [(date)]="value"
                           controlClass="form-control"
                           format="text">
        </datepicker-select>
        <ng-container *ngIf="inputOptions?.input">
            <input *ngIf="!inputOptions.rows"
                   [(ngModel)]="value"
                   [placeholder]="inputOptions.input"
                   required
                   type="text"
                   class="form-control">
            <textarea *ngIf="inputOptions.rows"
                      [(ngModel)]="value"
                      [placeholder]="inputOptions.input"
                      [rows]="inputOptions.rows"
                      [required]="!inputWithEmptyOptions.allowEmpty"
                      class="form-control">
            </textarea>
        </ng-container>
    </div>
</div>
<div class="modal-footer d-flex"
     [class.flex-column]="confirmBtns?.length > 1"
     [class.align-items-stretch]="confirmBtns?.length > 1">
    <button (click)="respond(false)"
            *ngIf="!(options?.showCancelBtn === false)"
            type="button"
            class="btn btn-outline-secondary"
            [class.m-0]="confirmBtns?.length > 1"
            [class.mt-2]="confirmBtns?.length > 1"
            [class.order-1]="confirmBtns?.length > 1">
        Cancel
    </button>
    <button *ngIf="!confirmBtns"
            (click)="respond(true)"
            [disabled]="isDisabled()"
            type="button"
            class="btn btn-{{confirmBtn?.colorClass || 'danger'}}">
        {{confirmBtn?.text || 'OK'}}
    </button>
    <button *ngFor="let btn of confirmBtns; let f = first"
            (click)="respond(btn.confirmValue || true)"
            [disabled]="isDisabled()"
            type="button"
            class="btn btn-{{btn.colorClass || 'danger'}}"
            [class.m-0]="confirmBtns.length > 1"
            [class.mt-2]="!f && confirmBtns.length > 1"
            [attr.data-test-id]="btn.dataTestId">
        {{btn.text || 'OK'}}
    </button>
</div>
